import topic from '../topic'
export const userAPI = {
    getUsers: topic.userTopic + '/get_users',
    getAllUsersV1:topic.userTopic + '/get_all_users_v1',
    getUserById: topic.userTopic + '/get_user_by_id',
    addUser: topic.userTopic + '/add_user',
    editUser: topic.userTopic + '/edit_user',
    deleteUserByIds: topic.userTopic + '/delete_user_by_ids',
    userLogin: topic.userTopic + '/login',
    userLogout: topic.userTopic + '/logout',
    logUserId: topic.userTopic + '/log_user_id',
    logMenu: topic.userTopic + '/log_menu',
    resetUserPass: topic.userTopic + '/reset_user_pass',
    readCardLogin: topic.userTopic + '/read_card_login',
    getUserByRfidNo: topic.userTopic + '/get_user_by_rfid_no',
    cancel_delete: topic.userTopic + '/cancel_delete', //恢复 
    getVersion:topic.userTopic + '/get_version',//判断版本
}